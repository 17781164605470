import { useEffect, useState, Fragment, useRef } from 'react';
import {Link, useParams} from "react-router-dom";
import axios from 'axios';
import Axios from 'axios';

import './links.css';

import Linkk from '../../../images/link1.png';
import Twitch from '../../../images/twitch.png';
import Diamond from '../../../images/diamond.png';
import WhiteArrow from '../../../images/whitearrow.png';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

// import useLinkClickTracker from '../../Analytics/useLinkClickTracker.tsx';


const PayAsk = ({ creatorId, wall, excLink, paywallLinkId, open, setOpen }) => {
  const [email, setEmail] = useState('');
  const [submittable, setSubmitttable] = useState(false);
  const [loading, setLoading] = useState(false);
  let amount = wall.price;

  const type = 'link';

  const initializePayment = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      creator_id: creatorId,
      email,
      amount,
      type,
      paywall_id: paywallLinkId,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/subscriptions/initialize_payment/`, data);
      if (response.data.status === 'success') {
        window.location.href = response.data.authorization_url;
      } else {
        alert('Payment initialization failed');
      }
    } catch (error) {
      console.error('Error initializing payment:', error);
      alert('Payment initialization failed');
    } finally {
      setLoading(false);
    }
  };

  // const location = useLocation();
  // const query = new URLSearchParams(location.search);
  // const reference = query.get('reference');

  // useEffect(() => {
  //   const verifyPayment = async () => {
  //     const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/verify-payment/?reference=${reference}`);
      
  //     // Handle the response data, update UI accordingly
  //   };

  //   if (reference) {
  //     verifyPayment();
  //   }
  // }, [reference]);

  useEffect(() => {
     if (email != '') {
      setSubmitttable(true);
      console.log(submittable)
     } else {
      setSubmitttable(false)
     }
  }, [email]);

  return (
    <Modal className='w-full' style={{borderRadius: '20px', width: '95%'}} open={open} onClose={() => setOpen(false)} center>    
      <div className='w-full delete-section paywall event-overall'>
        <h2 style={{marginBottom: '12px'}} className='text-sm font-semibold !text-start'>Purchase <span className="text-neutral-400 font-bold">{wall.title}</span> <br />The link would be sent to your mail</h2>
        <form className='m-0 p-0 flex flex-col items-center w-full' onSubmit={(e) => initializePayment(e)}>
          <input className='w-full p-2 text-xs' type="email" required placeholder="Enter receiving email" value={email} onChange={(e) => setEmail(e.target.value)} />
          {loading ? 
            <div
                className="inline-block h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                  className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
              </div> :
            <button type='submit' className='delete-btn' style={{marginTop: '4px'}}>Pay ₦{wall.price}</button>
            }
          </form>
        
        <button className='bg-neutral-300' onClick={() => setOpen(false)}>Cancel</button>
      </div>
    </Modal>
  )
}




const Links = ({ links  }) => {
  const { id } = useParams();
  
  const handleLinkClick = (linkId) => {
    axios.post(`${process.env.REACT_APP_API_URL}/api/profile/track-link-click/${linkId}/`, {})
        .then(response => console.log('Link click recorded:', response.data))
        .catch(error => console.error('Error recording link click:', error));
  };

  const [open, setOpen] = useState(false)

  const [openStates, setOpenStates] = useState(Array(links.length).fill(false));

  const handleOpen = (index) => {
    console.log(openStates )
    setOpenStates(openStates.map((open, i) => (i === index ? true : open)));
  };

  const handleClose = (index) => {
    setOpenStates(openStates.map((open, i) => (i === index ? false : open)));
  };

  // const [openEventStates, setOpenEventStates] = useState(Array(links.length).fill(false));

  // const handleEventOpen = (index) => {
  //   console.log(openStates )
  //   setOpenEventStates(openEventStates.map((open, i) => (i === index ? true : open)));
  // };

  // const handleEventClose = (index) => {
  //   setOpenEventStates(openEventStates.map((open, i) => (i === index ? false : open)));
  // };

  useEffect(() => {
    setOpenStates(Array(links.length).fill(false));
    // setOpenEventStates(Array(links.length).fill(false));
  }, [links])

  // const handleLinkClick = (linkId) => {
  //   fetch(`/api/track-link-click/${linkId}/`, {
  //     method: 'POST',
  //   });
  // };
  
    let linksDetails;
    linksDetails = links.map((linking, index) => {
      return (
        <>
        {linking.type == 'event_ticket' &&
          <div className='p-[1.6px] rounded-[12px] bg-gradient-to-r from-[#F84F94] to-[#B3EA44] mb-3'>
          <div onClick={() => handleEventOpen(index)} className="links seperator cursor-pointer">
            <div className="link-link">
              {linking.thumb != null && linking.thumb != '' && linking.thumb != undefined ? <img src={linking.thumb} className="link-img" /> : <img src={linking.thumb} className="link-img invisible" />}
              <div className="link-desc">
                <h3>{linking.title}</h3>
              </div>
            </div>
            <img src={Diamond} className="link-arrow !w-[18px]" />
            {/* <img src={WhiteArrow} className="link-arrow" /> */}
          </div>
        </div>
        
        }

      {linking.type == 'paywalled' &&
            <div className='p-[1.6px] rounded-[12px] bg-gradient-to-r from-[#F84F94] to-[#B3EA44] mb-3'>
            <div onClick={() => handleOpen(index)} className="links seperator cursor-pointer">
              <div className="link-link">
                {linking.thumb != null && linking.thumb != '' && linking.thumb != undefined ? <img src={linking.thumb} className="link-img" /> : <img src={linking.thumb} className="link-img invisible" />}
                <div className="link-desc">
                  <h3>{linking.title}</h3>
                </div>
              </div>
              <img src={Diamond} className="link-arrow !w-[18px]" />
              {/* <img src={WhiteArrow} className="link-arrow" /> */}
            </div>
          </div>
              
        }

          {linking.type == 'normal' &&
            <a href={linking.url} onClick={() => handleLinkClick(linking.id)} className="links seperator mb-3" target="_blank">
            <div className="link-link">
              {linking.thumb != null && linking.thumb != '' && linking.thumb != undefined ? <img src={linking.thumb} className="link-img" /> : <></>}
              <div className="link-desc">
                <h3>{linking.title}</h3>
              </div>
            </div>
            {linking.thumb != null && linking.thumb != '' && linking.thumb != undefined ? <img src={WhiteArrow} className="link-arrow" /> : <></>}
            {/* <img src={WhiteArrow} className="link-arrow" /> */}
          </a>
         
              
        }
        
        <PayAsk creatorId={linking.creator} excLink={linking.link} paywallLinkId={linking.id} wall={linking} open={openStates[index]} setOpen={() => handleClose(index)} />
        </>
      )
    });

  return (
    <div className="link-section">
      {linksDetails}
      {/*<a href="#" className="links seperator">
        <div className="link-link">
          <img src={Linkk} className="link-img" />
          <div className="link-desc">
            <h3>Link Text</h3>
          </div>
        </div>
        <img src={WhiteArrow} className="link-arrow" />
      </a>
      <a href="#" className="links">
        <div className="link-link">
          <img src={Twitch} className="link-img" />
          <div className="link-desc">
            <h3>Link Text</h3>
          </div>
        </div>
        <img src={WhiteArrow} className="link-arrow" />
      </a>*/}
    </div>
  );
}

export default Links;
